import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10aace4c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _54feed96 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _1301ad15 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _1a685109 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _b572e25c = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _4b5b1d3a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _85e67900 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _c3d118fa = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _4b324934 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _1e4a0899 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _e3c5ebea = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _9f2456c4 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _d2abcd3e = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _3cd1700a = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _46534c8f = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _df05f418 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _70292b75 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _471881c4 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _2a354d19 = () => interopDefault(import('../pages/refund/_id.vue' /* webpackChunkName: "pages/refund/_id" */))
const _b7d05f12 = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _6bd3b529 = () => interopDefault(import('../pages/unsubscribe/_hash.vue' /* webpackChunkName: "pages/unsubscribe/_hash" */))
const _6acd0709 = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _0ef42d04 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _10aace4c,
    name: "index___en"
  }, {
    path: "/lv",
    component: _10aace4c,
    name: "index___lv"
  }, {
    path: "/ru",
    component: _10aace4c,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _54feed96,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _1301ad15,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _1a685109,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _b572e25c,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _4b5b1d3a,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _85e67900,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _c3d118fa,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _4b324934,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _1e4a0899,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _e3c5ebea,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _9f2456c4,
    name: "search___en"
  }, {
    path: "/en/tickets",
    component: _d2abcd3e,
    name: "tickets___en"
  }, {
    path: "/lv/cart",
    component: _54feed96,
    name: "cart___lv"
  }, {
    path: "/lv/categories",
    component: _1301ad15,
    name: "categories___lv"
  }, {
    path: "/lv/cookies",
    component: _1a685109,
    name: "cookies___lv"
  }, {
    path: "/lv/events",
    component: _b572e25c,
    name: "events___lv"
  }, {
    path: "/lv/login",
    component: _4b5b1d3a,
    name: "login___lv"
  }, {
    path: "/lv/logout",
    component: _85e67900,
    name: "logout___lv"
  }, {
    path: "/lv/payment",
    component: _c3d118fa,
    name: "payment___lv"
  }, {
    path: "/lv/profile",
    component: _4b324934,
    name: "profile___lv"
  }, {
    path: "/lv/register",
    component: _1e4a0899,
    name: "register___lv"
  }, {
    path: "/lv/restore",
    component: _e3c5ebea,
    name: "restore___lv"
  }, {
    path: "/lv/search",
    component: _9f2456c4,
    name: "search___lv"
  }, {
    path: "/lv/tickets",
    component: _d2abcd3e,
    name: "tickets___lv"
  }, {
    path: "/ru/cart",
    component: _54feed96,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _1301ad15,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _1a685109,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _b572e25c,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _4b5b1d3a,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _85e67900,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _c3d118fa,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _4b324934,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _1e4a0899,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _e3c5ebea,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _9f2456c4,
    name: "search___ru"
  }, {
    path: "/ru/tickets",
    component: _d2abcd3e,
    name: "tickets___ru"
  }, {
    path: "/en/profile/tickets",
    component: _3cd1700a,
    name: "profile-tickets___en"
  }, {
    path: "/lv/profile/tickets",
    component: _3cd1700a,
    name: "profile-tickets___lv"
  }, {
    path: "/ru/profile/tickets",
    component: _3cd1700a,
    name: "profile-tickets___ru"
  }, {
    path: "/en/categories/:cid",
    component: _46534c8f,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _df05f418,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _70292b75,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _471881c4,
    name: "payment-hash___en"
  }, {
    path: "/en/refund/:id?",
    component: _2a354d19,
    name: "refund-id___en"
  }, {
    path: "/en/restore/:key",
    component: _b7d05f12,
    name: "restore-key___en"
  }, {
    path: "/en/unsubscribe/:hash?",
    component: _6bd3b529,
    name: "unsubscribe-hash___en"
  }, {
    path: "/lv/categories/:cid",
    component: _46534c8f,
    name: "categories-cid___lv"
  }, {
    path: "/lv/events/:eid",
    component: _df05f418,
    name: "events-eid___lv"
  }, {
    path: "/lv/groups/:id?",
    component: _70292b75,
    name: "groups-id___lv"
  }, {
    path: "/lv/payment/:hash",
    component: _471881c4,
    name: "payment-hash___lv"
  }, {
    path: "/lv/refund/:id?",
    component: _2a354d19,
    name: "refund-id___lv"
  }, {
    path: "/lv/restore/:key",
    component: _b7d05f12,
    name: "restore-key___lv"
  }, {
    path: "/lv/unsubscribe/:hash?",
    component: _6bd3b529,
    name: "unsubscribe-hash___lv"
  }, {
    path: "/ru/categories/:cid",
    component: _46534c8f,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _df05f418,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _70292b75,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _471881c4,
    name: "payment-hash___ru"
  }, {
    path: "/ru/refund/:id?",
    component: _2a354d19,
    name: "refund-id___ru"
  }, {
    path: "/ru/restore/:key",
    component: _b7d05f12,
    name: "restore-key___ru"
  }, {
    path: "/ru/unsubscribe/:hash?",
    component: _6bd3b529,
    name: "unsubscribe-hash___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _6acd0709,
    name: "events-eid-tickets___en"
  }, {
    path: "/lv/events/:eid/tickets",
    component: _6acd0709,
    name: "events-eid-tickets___lv"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _6acd0709,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _0ef42d04,
    name: "slug___en"
  }, {
    path: "/lv/:slug",
    component: _0ef42d04,
    name: "slug___lv"
  }, {
    path: "/ru/:slug",
    component: _0ef42d04,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
